import { authHeader } from './auth-header';
import axios from 'axios';

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {
    return axios
      .post(process.env.VUE_APP_API_URL + 'users/login', {
        email,
        password
      })
      .then(response => {
        if (typeof response.data.data !==  "undefined" && response.data.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data.data));
          return response.data.data;
        } else {
            return Promise.reject(response.data.message);
        }
      });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
